<template>
  <div class="view-wrapper">
    <v-snackbar v-model="msg" :timeout="3000" top>
      {{ result }}
    </v-snackbar>
    <div class="view">
      <v-toolbar class="my-bar">
        <v-text-field
          v-model="search"
          hide-details
          prepend-inner-icon="search"
          single-line
          dense
          filled
          rounded
          clearable
          @click:clear="clearSearch"
        ></v-text-field>
        <v-btn v-if="rightFilter('w')" icon @click="add">
          <v-icon>add</v-icon>
        </v-btn>
        <v-spacer />
      </v-toolbar>
      <div class="my-content md-content">
        <v-list>
          <v-list-item-group v-model="selected" color="primary">
            <template v-for="(item, i) in filteredItems">
              <v-list-item :key="i">
                <v-list-item-content>
                  <v-list-item-title v-html="item.name"></v-list-item-title>
                  <v-list-item-subtitle
                    v-html="item.userName"
                  ></v-list-item-subtitle>
                </v-list-item-content>
                <v-list-item-icon v-if="item.deleted">
                  <v-icon>block</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-divider :key="item.key"></v-divider>
            </template>
          </v-list-item-group>
        </v-list>
      </div>
    </div>
    <div v-if="item" class="view">
      <v-tabs class="toolbar">
        <v-tab>Общие</v-tab>
        <v-tab v-if="item.id">Доступ</v-tab>
        <v-toolbar class="my-bar">
          <v-spacer></v-spacer>
          <v-btn icon @click="close">
            <v-icon>close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-tab-item>
          <div class="view" style="position: absolute; overflow: hidden; width: 100%;">
            <v-toolbar class="my-bar">
<!--              <v-btn v-if="rightFilter('w')" icon :disabled="!detail || !detail.valid" @click="save">
                <v-icon>save</v-icon>
              </v-btn>-->
              <SaveButton
              :handler="save"
              :additional-rules="!detail || !detail.valid"
              >

              </SaveButton>
              <v-btn v-if="rightFilter('d')" icon :disabled="!item.id" @click="rem">
                <v-icon v-if="!item.deleted">block</v-icon>
                <v-icon v-else>check</v-icon>
              </v-btn>
              <v-spacer></v-spacer>
            </v-toolbar>
            <div class="my-content">
              <Detail :vm="this" />
              <div
                class="my-content"
                style="display: flex; flex-direction: column; align-items: center"
              >
                <v-btn v-if="item.id" color="primary" @click="resetPwd">
                  Сбросить пароль
                </v-btn>
              </div>
            </div>
          </div>
        </v-tab-item>
        <v-tab-item>
          <Access :vm="this"></Access>
        </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import fetch from "../utils/fetch";
import Detail from "../components/users/Detail";
import Access from "../components/users/Access";
import js2vm from "../utils/js2vm";
import vm2js from "../utils/vm2js";
import rightFilter from "../filters/right.filter";
import SaveButton from "@/components/ButtonFacade";

let Item = function(d) {
  let self = this;
  js2vm(self, d);
};

export default {
  data: () => ({
    rightFilter,
    selected: undefined,
    items: [],
    search: "",
    item: null,
    adminMode: true,
    detail: undefined,
    msg: false,
    error: false,
    result: ""
  }),
  methods: {
    async fetchItems() {
      const res = await fetch.get("/api/auth/get-list");
      console.log("/api/auth/get-list ------- ", res); 
      
      if (res)
        res.forEach(r => {
          this.items.push(new Item(r));
        });
    },
    clearSearch() {
      this.search = "";
    },
    async save() {
      const res = await fetch.post("/api/auth/save", vm2js(this.item));
      if (res) {
        if (this.item.id) {
          Object.assign(
            this.items.find(i => {
              return i.id === this.item.id;
            }),
            this.item
          );
        } else {
          this.item.id = res;
          this.items.unshift(new Item(vm2js(this.item)));
        }
        
        
      }
    },
    add() {
      this.close().then(() => {
        this.item = new Item({});
      });
    },
    close() {
      return new Promise(resolve => {
        this.item = undefined;
        this.selected = undefined;
        resolve();
      });
    },
    async rem() {
      if (!this.item.deleted) {
        const res = await fetch.post("/api/auth/delete?id=" + this.item.id);
        if (res) {
          this.item.deleted = true;
          Object.assign(
            this.items.find(i => {
              return i.id === this.item.id;
            }),
            this.item
          );
        }
      } else {
        const res = await fetch.post("/api/auth/restore?id=" + this.item.id);
        if (res) {
          this.item.deleted = false;
          Object.assign(
            this.items.find(i => {
              return i.id === this.item.id;
            }),
            this.item
          );
        }
      }
    },
    async resetPwd() {
      const res = await fetch.post("/api/auth/reset-pwd?id=" + this.item.id);
      if (res) {
        this.valid = true;
        this.pwdNeedChange = false;
        this.error = false;
        this.result = "Пароль сброшен успешно! Пароль по умолчанию - 123456";
        this.msg = true;
      } else {
        this.error = true;
        this.result = "Что-то пошло не так!";
        this.msg = true;
      }
    }
  },
  watch: {
    selected: function(val) {
      if (val == undefined)
        if (this.item) return (this.item = undefined);
        else return;
      this.item = new Item(this.filteredItems[val]);
    }
  },
  computed: {
    filteredItems() {
      
      if (!this.search || this.search === null || 0 === this.search)
        return this.items;
      return _.orderBy(
        this.items.filter(item => {
          return (
            item.name.toLowerCase().includes(this.search.toLowerCase()) ||
            item.userName.toLowerCase().includes(this.search.toLowerCase())
          );
        }),
        "headline"
      );
    }
  },
  mounted() {
    this.fetchItems();
  },
  components: {
    SaveButton,
    Detail, Access
  }
};
</script>
